





































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, INIT_CREATE } from '../store';
import { format, startOfWeek } from '@/filter/dateFormat';
import EditAppointmentDialog from '../components/EditAppointmentDialog.vue';
import CancelAppointmentDialog from '../components/CancelAppointmentDialog.vue';
import CreateAppointmentDialog from '../components/CreateAppointmentDialog.vue';
import ViewAppointmentDialog from '../components/ViewAppointmentDialog.vue';
import FullCalendar from '../../../components/FullCalendar.vue';
import { Appointment, AppointmentList, ColorDictionary } from '@/modules/appointment/types';
import { ApiResponse } from '@/components/types';
import { icon } from '@/modules/appointment/model';
import { AuthorizationMixin } from '@/views/mixin';
import { routeToLocation } from '@/router';

const Appointment = namespace('appointment');

@Component({
  components: {
    FullCalendar,
    EditAppointmentDialog,
    CancelAppointmentDialog,
    CreateAppointmentDialog,
    ViewAppointmentDialog
  }
})
export default class List extends AuthorizationMixin {
  @Appointment.State('list') list!: AppointmentList;
  @Appointment.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse>;
  @Appointment.Mutation(INIT_CREATE) initCreate!: (data: object) => void;

  loading: boolean = false;

  config = {
    height: 'auto',
    validRange: {
      start: startOfWeek(Date.now(), { weekStartsOn: 1 })
    }
  };

  get viewAppointment(): string {
    return this.$route.query['show'] as string;
  }

  set viewAppointment(show: string) {
    if (!show) {
      const { show, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, show } })).catch(() => {});
  }

  get editAppointment(): string {
    return this.$route.query['edit'] as string;
  }

  set editAppointment(edit: string) {
    if (!edit) {
      const { edit, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, edit } })).catch(() => {});
  }

  get cancelAppointment(): string {
    return this.$route.query['cancel'] as string;
  }

  set cancelAppointment(cancel: string) {
    if (!cancel) {
      const { cancel, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } }));
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, cancel } }));
  }

  get events() {
    return this.list.items.map((appointment) => ({
      ...appointment,
      baseTitle: appointment.title,
      title: `${appointment.title} ${appointment.car ? ` - ${appointment.car.name}` : ''}${this.isAdmin() ? `\n${appointment.employee.name}` : ''}`,
      className: this.color(appointment),
      borderColor: '#ffffff',
      icon: icon(appointment),
      startDate: format(appointment.start, 'dd.MM.yyyy'),
      beginDate: format(appointment.begin, 'dd.MM.yyyy'),
      endDate: format(appointment.end, 'dd.MM.yyyy'),
      startTime: format(appointment.start, 'HH:mm'),
      beginTime: format(appointment.begin, 'HH:mm'),
      endTime: format(appointment.end, 'HH:mm'),
      baseObject: appointment
    }));
  }

  onClick(event: Appointment) {
    this.viewAppointment = event.id;
  }

  get defaultView() {
    return this.$vuetify.breakpoint.mdAndUp ? 'agendaWeek' : 'agendaDay';
  }

  color(appointment: Appointment): string {
    return this.bgColors[appointment.employee.id];
  }

  onDayClick(event: any) {
    if (!event) return;

    const begin = event.format('YYYY-MM-DD HH:mm:ss');
    const end = event.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');

    setTimeout(() => {
      this.initCreate({ employee: this.$auth.user().id, start: begin, begin, end });
    }, 200);
  }

  select({ start, end }: any) {
    const begin = start.format('YYYY-MM-DD HH:mm:ss');
    const ends = end.format('YYYY-MM-DD HH:mm:ss');

    setTimeout(() => {
      this.initCreate({ employee: this.$auth.user().id, start: begin, begin, end: ends });
    }, 200);
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }

  get bgColors(): ColorDictionary {
    const colors = ['green darken-3', 'orange darken-4', 'light-blue darken-3', 'cyan darken-3', 'teal darken-3', 'purple darken-3'];

    const employees: string[] = this.list.items
      .reduce((acc: string[], { employee }: Appointment) => {
        if (acc.includes(employee.id)) {
          return acc;
        }

        acc.push(employee.id);

        return acc;
      }, [])
      .sort((first: string, second: string) => first.localeCompare(second));

    return colors.reduce((acc: ColorDictionary, color: string) => {
      if (employees.length === 0) {
        return acc;
      }

      acc[employees.shift() || ''] = color;

      return acc;
    }, {});
  }
}
